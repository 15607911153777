import Vue from "vue";
import VueRouter from "vue-router";
import axios from "../plugins/axios";
import { i18n } from "../plugins/vue-i18n";

import Main from "../pages/client/Main.vue";

// import { BoardRoutes } from "./board.router";
// import { QuestionRoutes } from "./question.router";

Vue.use(VueRouter);

const routes = [
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // 사용자 페이지 라우트
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    {
        // Main 페이지
        path: "/",
        component: Main,
    },

    {
        // 가이드
        path: "/guide",
        component: () => import(/* webpackChunkName: "guide" */ "../pages/client/guide/guide-app.vue"),
        props: true,
    },

    // 게시판
    {
        // FAQ
        path: "/center/faqs",
        component: () => import(/* webpackChunkName: "center" */ "../pages/client/center/faq.vue"),
        props: true,
    },
    {
        // 공지사항
        path: "/center/notice",
        component: () => import(/* webpackChunkName: "center" */ "../pages/client/center/notice.vue"),
        props: true,
    },
    {
        // 공지사항
        path: "/center/notice/:_notification",
        component: () => import(/* webpackChunkName: "center" */ "../pages/client/center/notice.vue"),
        props: true,
    },

    // 폼
    {
        // 문의 및 제휴
        path: "/inquiry",
        component: () => import(/* webpackChunkName: "form" */ "../pages/client/form/Inquiry.vue"),
        props: true,
    },

    // 개인정보처리방침
    {
        // 개인정보처리방침
        path: "/privacy-policy",
        component: () => import(/* webpackChunkName: "sub" */ "../pages/client/center/PrivacyPolicyPage.vue"),
        props: true,
    },

    // kcp
    // {
    //     path: "/common/kcp/cert/request",
    //     component: () => import("../pages/common/kcp/KCPCertRequest.vue"),
    //     props: true,
    // },
    // {
    //     path: "/common/kcp/cert/response",
    //     component: () => import("../pages/common/kcp/KCPCertResponse.vue"),
    //     props: true,
    // },

    // 로그인/회원가입
    // {
    //     // 로그인
    //     path: "/login",
    //     component: () => import(/* webpackChunkName: "login" */ "../pages/client/login/main.vue"),
    //     props: true,
    // },
    // {
    //     // 비밀번호 찾기
    //     path: "/login/find-password",
    //     component: () => import(/* webpackChunkName: "login" */ "../pages/client/login/find-password.vue"),
    //     props: true,
    // },
    // {
    //     // 아이디 찾기
    //     path: "/login/find-id",
    //     component: () => import(/* webpackChunkName: "login" */ "../pages/client/login/find-id.vue"),
    //     props: true,
    // },
    // {
    //     // 회원가입
    //     path: "/join",
    //     component: () => import(/* webpackChunkName: "join" */ "../pages/client/join/main.vue"),
    //     props: true,
    // },
    // {
    //     // 회원가입 폼
    //     path: "/join/type",
    //     component: () => import(/* webpackChunkName: "join" */ "../pages/client/join/type.vue"),
    //     props: true,
    // },
    // {
    //     // 회원가입 폼
    //     path: "/join/form",
    //     component: () => import(/* webpackChunkName: "join" */ "../pages/client/join/form.vue"),
    //     props: true,
    // },
    // {
    //     // 회원가입 완료
    //     path: "/join/complete",
    //     component: () => import(/* webpackChunkName: "join" */ "../pages/client/join/complete.vue"),
    //     props: true,
    // },

    // 마이페이지
    // {
    //     // 1:1 문의
    //     path: "/mypage/question",
    //     component: () => import(/* webpackChunkName: "question" */ "../pages/client/mypage/question.vue"),
    //     children: [...QuestionRoutes],
    //     props: true,
    // },
    // {
    //     // 회원 정보 상세
    //     path: "/mypage/myinfo",
    //     component: () => import(/* webpackChunkName: "mypage" */ "../pages/client/mypage/myinfo.vue"),
    //     props: true,
    // },
    // {
    //     // 회원 정보 변경
    //     path: "/mypage/myinfo/:tab",
    //     component: () => import(/* webpackChunkName: "mypage" */ "../pages/client/mypage/myinfo.vue"),
    //     props: true,
    // },
    // {
    //     // 회원 탈퇴
    //     path: "/mypage/leave",
    //     component: () => import(/* webpackChunkName: "mypage" */ "../pages/client/mypage/leave.vue"),
    //     props: true,
    // },
    // {
    //     // 회원 탈퇴
    //     path: "/mypage/leave/:tab",
    //     component: () => import(/* webpackChunkName: "mypage" */ "../pages/client/mypage/leave.vue"),
    //     props: true,
    // },

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // 관리자 페이지 라우트
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    {
        // 관리자 메인
        path: "/console",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/Main.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 메인
        path: "/console/product",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/product/ProductListPage.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 메인
        path: "/console/product/:_product",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/product/SerialListPage.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 대시보드
        path: "/console/dashboard",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/Dashboard.vue"),
        props: true,
    },
    {
        // 관리자 - 게시판관리
        path: "/console/boards",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/board/BoardList.vue"),
    },
    {
        // 관리자 - 게시판관리
        path: "/console/boards/create",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/board/BoardView.vue"),
    },
    {
        // 관리자 - 게시판관리
        path: "/console/boards/:_board",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/board/BoardView.vue"),
    },
    {
        // 관리자 - 폼메일관리
        path: "/console/forms",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/form/FormList.vue"),
    },
    {
        // 관리자 - 폼메일관리
        path: "/console/forms/:_form",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/form/FormView.vue"),
    },
    {
        // 관리자 - 회원등급
        path: "/console/users/levels",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/user/LevelList.vue"),
        props: true,
    },
    {
        // 관리자 - 회원등급
        path: "/console/users/withdrawals",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/user/WithdrawalList.vue"),
        props: true,
    },
    {
        // 관리자 - 사용자
        path: "/console/users",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/user/UserList.vue"),
        props: true,
    },
    {
        // 관리자 - 사용자
        path: "/console/users/create",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/user/UserView.vue"),
        props: true,
    },
    {
        // 관리자 - 사용자
        path: "/console/users/:_user",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/user/UserView.vue"),
        props: true,
    },
    {
        // 관리자 - 공지사항
        path: "/console/center/notifications",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/center/Notification.vue"),
        props: true,
    },
    {
        // 관리자 - FAQ
        path: "/console/center/faqs",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/center/Faq.vue"),
        props: true,
    },
    {
        // 관리자 - 1:1문의
        path: "/console/center/questions",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/center/Question.vue"),
        props: true,
    },
    {
        // 관리자 - 서비스 이용약관
        path: "/console/terms",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/terms/TermsList.vue"),
        props: true,
    },
    {
        // 관리자 - 배너
        path: "/console/banners",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/banner/BannerList.vue"),
        props: true,
    },
    {
        // 관리자 - 배너
        path: "/console/banners/create",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/banner/BannerView.vue"),
        props: true,
    },
    {
        // 관리자 - 배너
        path: "/console/banners/:_banner",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/banner/BannerView.vue"),
        props: true,
    },
    {
        // 관리자 - 팝업
        path: "/console/popups",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/popup/PopupList.vue"),
        props: true,
    },
    {
        // 관리자 - 설정
        path: "/console/setting",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/setting/SettingView.vue"),
        props: true,
    },
    {
        // 관리자 - 비밀번호
        path: "/console/password",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/setting/PasswordView.vue"),
        props: true,
    },
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,

    scrollBehavior: (to, from, savedPosition) => {
        let scrollTo = undefined;

        if (to.hash) {
            scrollTo = to.hash;
        } else if (savedPosition) {
            scrollTo = savedPosition.y;
        }

        if (scrollTo != undefined)
            return {
                selector: scrollTo,
                behavior: "smooth",
            };
    },
});

router.beforeEach((to, from, next) => {
    try {
        const accessToken = sessionStorage.getItem("accessToken");
        axios.defaults.headers.common["Authorization"] = !!accessToken ? `Bearer ${accessToken}` : "";
        axios.defaults.headers.common["Accept-Language"] = i18n.locale;

        const route = routes.find((route) => route.path == to.path);
        if (!route?.scope) next();
        else {
            if (!accessToken) {
                if (0 <= to.path.indexOf("console")) {
                    next();
                    return;
                } else throw new Error("로그인 후 이용가능합니다");
            }

            const payload = JSON.parse(atob(accessToken.split(".")[1]));
            const scope = payload?.scope || [];

            if (!scope.find((scope) => route.scope.includes(scope))) {
                throw new Error("접근권한이 없습니다");
            }

            next();
        }
    } catch (error) {
        alert(error.message);
        window.location.href = routes.find((route) => route.path == from.path)?.path ?? routes.find((route) => route.path == "/")?.path ?? "about:blank";
    }
});

export default router;
