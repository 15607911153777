var render = function render(){
  var _vm$serial, _vm$serial$product, _vm$serial2, _vm$serial3, _vm$serial3$product, _vm$serial4, _vm$serial4$product;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    staticClass: "card",
    attrs: {
      "flat": ""
    }
  }, [_c('div', {
    staticClass: "card__wrap"
  }, [_c('h3', {
    staticClass: "card__op"
  }, [_vm._v("ORIGINAL PRODUCT")]), _c('h2', {
    staticClass: "card__tit"
  }, [_vm._v("정품(正品)")]), _c('v-row', {
    staticClass: "card__list row--sm"
  }, [_c('v-col', [_c('h4', {
    staticClass: "list-tit"
  }, [_vm._v("제품명 : ")]), _c('span', {
    staticClass: "info"
  }, [_vm._v(_vm._s((_vm$serial = _vm.serial) === null || _vm$serial === void 0 ? void 0 : (_vm$serial$product = _vm$serial.product) === null || _vm$serial$product === void 0 ? void 0 : _vm$serial$product.name))])]), _c('v-col', [_c('h4', {
    staticClass: "list-tit"
  }, [_vm._v("식별번호 : ")]), _c('span', {
    staticClass: "info"
  }, [_vm._v(_vm._s((_vm$serial2 = _vm.serial) === null || _vm$serial2 === void 0 ? void 0 : _vm$serial2.code))])]), _c('v-col', [_c('h4', {
    staticClass: "list-tit"
  }, [_vm._v("식품 유형 : ")]), _c('span', {
    staticClass: "info"
  }, [_vm._v(_vm._s((_vm$serial3 = _vm.serial) === null || _vm$serial3 === void 0 ? void 0 : (_vm$serial3$product = _vm$serial3.product) === null || _vm$serial3$product === void 0 ? void 0 : _vm$serial3$product.type))])]), _c('v-col', [_c('h4', {
    staticClass: "list-tit"
  }, [_vm._v("내포장재질 : ")]), _c('span', {
    staticClass: "info"
  }, [_vm._v(_vm._s((_vm$serial4 = _vm.serial) === null || _vm$serial4 === void 0 ? void 0 : (_vm$serial4$product = _vm$serial4.product) === null || _vm$serial4$product === void 0 ? void 0 : _vm$serial4$product.material))])])], 1), _c('strong', {
    staticClass: "card__txt"
  }, [_vm._v(" 본 제품은 온라인 사이트를 통한"), _c('br'), _vm._v(" 재판매가 불가한 제품입니다. ")]), _c('v-sheet', {
    staticClass: "card__sheet",
    attrs: {
      "color": "rgba(0,0,0,0.4)"
    }
  }, [_c('div', {
    staticClass: "text-white font-size-14 line-height-15 font-weight-bold"
  }, [_vm._v(" 본 제품은 질병의 예방 및 치료를"), _c('br'), _vm._v(" 위한 의약품이 아닙니다. ")])]), _c('v-img', {
    staticClass: "card__ci",
    attrs: {
      "src": "/images/ci.svg",
      "max-width": "80px"
    }
  })], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }