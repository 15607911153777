<template>
    <v-card flat class="card">
        <div class="card__wrap">
            <h3 class="card__op">ORIGINAL PRODUCT</h3>
            <h2 class="card__tit">정품(正品)</h2>
            <v-row class="card__list row--sm">
                <v-col>
                    <h4 class="list-tit">제품명 :&nbsp;</h4>
                    <span class="info">{{ serial?.product?.name }}</span>
                </v-col>
                <v-col>
                    <h4 class="list-tit">식별번호 :&nbsp;</h4>
                    <span class="info">{{ serial?.code }}</span>
                </v-col>
                <v-col>
                    <h4 class="list-tit">식품 유형 :&nbsp;</h4>
                    <span class="info">{{ serial?.product?.type }}</span>
                </v-col>
                <v-col>
                    <h4 class="list-tit">내포장재질 :&nbsp;</h4>
                    <span class="info">{{ serial?.product?.material }}</span>
                </v-col>
            </v-row>
            <strong class="card__txt">
                본 제품은 온라인 사이트를 통한<br />
                재판매가 불가한 제품입니다.
            </strong>
            <v-sheet color="rgba(0,0,0,0.4)" class="card__sheet">
                <div class="text-white font-size-14 line-height-15 font-weight-bold">
                    본 제품은 질병의 예방 및 치료를<br />
                    위한 의약품이 아닙니다.
                </div>
            </v-sheet>

            <v-img src="/images/ci.svg" max-width="80px" class="card__ci"></v-img>
        </div>
    </v-card>
</template>

<script>
// import ClientPage from "@/sets/styles/apps/client-page.vue";
// import MainSection from "@/sets/styles/mains/main-section.vue";
// import PopupLayer from "@/components/client/popup/popup-layer.vue";
// import MainVisual from "@/components/client/main/main-visual.vue";
// import BtnPrimary from "@/components/publish/parents/buttons/btn-primary.vue";
import api from "@/api";

export default {
    components: {
        // ClientPage,
        // PopupLayer,
        // MainVisual,
        // MainSection,
        // BtnPrimary,
    },
    props: {},
    data() {
        return {
            serial: null,
        };
    },
    async mounted() {
        await this.init();
    },
    methods: {
        async init() {
            let { serial } = await api.v1.serials.get({
                params: { code: this.$route.query.code },
            });
            this.serial = serial;
        },
    },
};
</script>

<style lang="scss" scoped>
.card {
    min-width: 254px;
    text-align: center;
    font-size: 1.4rem;
    color: #fff;
    padding: 28px 18px;
    overflow: visible;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    &::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: calc(100% + 24px);
        height: calc(100% + 24px);
        background-image: linear-gradient(90deg, #a3a4a8 0%, #ededed 30%, #a3a4a8 56%);
        border-radius: 44px;
        z-index: -2;
    }
    &::after {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-image: url(/images/background.jpg);
        width: 100%;
        height: 100%;
        border-radius: 36px;
        z-index: -1;
    }
    &__op {
        color: #c0afc7;
        letter-spacing: 0.28rem !important;
        margin-bottom: 24px;
        font-size: 1.4rem;
    }
    &__tit {
        font-size: 4.8rem;
        margin-bottom: 30px;
        white-space: nowrap;
    }
    &__list {
        text-align: left;
        flex-direction: column;
        .info {
            word-break: keep-all;
        }
        .list-tit {
            display: inline-block;
        }
    }
    &__txt {
        line-height: 1.5;
        display: inline-block;
        margin: 30px 0 8px;
    }
    &__ci {
        margin: 0 auto;
    }
    &__sheet {
        border-radius: 8px;
        color: #fff;
        padding: 8px;
        margin-bottom: 30px;
    }
}

.font-weight-bold {
    font-weight: 700;
}
</style>
